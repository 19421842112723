.payment-form-container {
    max-width: 60vw;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
  }
  
  .form-title {
    font-size: 33px;
    font-weight: 300;
    line-height: 1.5em;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 4rem;
  }

  .form-title-null {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 5rem;
  }
  
  .form-subtitle {
    text-align: center;
    font-size: 12px;
    margin-bottom: 2rem;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
  
  .form-group {
    margin-bottom: 33px;
  }
  .form-group select {
    width: 100%;
    padding: 1rem;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    background-image: url('/src/assests/Home/down_arrow.svg');
    background-size: 8%;
    background-repeat: no-repeat;
    background-position: 98% center;
  }

/* Styling for the custom arrow icon */
.form-group select::after {
  content: '\25BC'; /* Unicode character for down arrow */
  position: absolute;
  top: 50%;
  right: 5%; /* Adjusted position */
  transform: translateY(-50%);
  display: block;
}
  /* Styling for the arrow icon */
  .form-group select::-ms-expand {
      display: none; /* Remove arrow icon in IE */
  }

  .form-group select::-webkit-inner-spin-button,
  .form-group select::-webkit-outer-spin-button {
      -webkit-appearance: none; /* Remove arrow icon in Chrome/Safari */
      margin: 0; /* Remove inner padding */
  }

  .form-group select:focus {
      outline: none; /* Remove outline when focused */
  }
  .form-group label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .form-group label span {
    margin-left: 5px;
  }
  .form-group input[type="text"],
  .form-group input[type="email"] {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .form-grid button {
    margin-bottom: 4rem;
    grid-column: span 2;
    width: 33%;
    padding: 10px;
    background-color: rgb(47, 107, 210);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
    justify-self: center; 
  }
  
  .form-grid button:hover {
    background-color: #0056b3;
  }
  ::placeholder {
    color: #808080; /* Gray color for placeholders */
  }
  
  .signup_sec.comm_auth_sec .payment-form-container{
    padding: 0;
  } 
 
@media (max-width:1200px) {
  .form-group input[type="text"], .form-group input[type="email"] {
    padding: 0.8rem;
  }
  .form-group select {
    padding: 0.8rem;
  }
  .form-group select::after {
    
    top: 65%;
    transform: translateY(-67%);
  }
  .solution-modal .nst_gateway_signupform_handle_button {
   margin-top: 0;
    margin-bottom: 15px;
  }
  .solution-modal .form-title {
    font-size: 22px;
  }
  .solution-modal .form-subtitle {
    font-size: 14px;
  }
  .solution-modal .form-grid .form-group {
    margin-bottom: 23px;
  }
  .form-group label {
    font-size: 15px;
    margin-bottom: 7px;
  }
}
@media screen and (max-width: 768px) {
  .payment-form-container {
    max-width: 90vw;
    margin: 0;
    padding: 20px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
  }

  .form-title-null {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5em;
    text-align: center;
    margin-bottom: 20px;
    padding: 0.5rem;
  }

}
@media screen and (max-width: 480px) {
  .form-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-group {
    width: 100%;
    margin-bottom: 18px;
  }
  .form-group input[type="text"], .form-group input[type="email"] {
    padding: 0.86rem;
  }
  .nst_gateway_signupform_handle_button {
  
    width: 100%;
  }
}