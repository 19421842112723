.flex-container-origin {
    position: relative;
    width: 100%;
    height: auto;
    background-color: rgb(255, 253, 252);
    overflow: hidden;
}

.circles {
    position: absolute;
    bottom: -44%;
    /* Move the circles further down */
    left: -7%;
    /* Move the circles further to the left */
    width: 30%;
    height: 0;
    padding-bottom: 30%;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 50%;
}

.circles::before {
    content: "";
    position: absolute;
    bottom: 5%;
    left: 6%;
    width: 85%;
    height: 0;
    padding-bottom: 85%;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 50%;
}

.flex-container {
    display: flex;
    margin: 3rem 111px 3rem 111px;
}

/* Left column style */
.left-column {
    display: flex;
    flex-direction: column;
    /* Set flex-direction to column */
    justify-content: center;
    z-index: 1;
    flex-basis: 100%;
}

/* Heading style */
.left-column h1 {
    font-size: 28px;
    width: 100%;
    margin: 0;
}

/* Paragraph style */
.left-column-subtitle {
    font-size: 14px;
    width: 30vw;
    margin-bottom: 1.5rem;
}

/* Button style */
.left-column-button {
    background-color: rgb(10, 98, 207);
    color: aliceblue;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    width: 10.5vw;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.left-column-button:hover {
    background-color: rgb(0, 115, 255);
}

/* Phone number text style */
.left-column-phone-number {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.left-column-phone-number span {
    padding-left: 0.5rem;
    color: rgb(0, 115, 255);
    text-decoration: none;
}

/* Right column style */
.right-column {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* Image style */
.right-column img {
    width: 100%;
    height: auto;
}

/* Phone number text style */
.phone-number {
    font-size: 16px;
}

/* Phone number span style */
.phone-number span {
    padding-left: 5px;
    color: rgb(47, 107, 210);
}

/* Business Overview section */
.bussiness-container {
    background-color: rgb(255, 255, 255);
    margin: 3rem 111px 3rem 111px;
}

.bussiness-flex-container {
    display: flex;
    align-items: center;
}

.bussiness-flex-container-left {
    width: 22%;
    vertical-align: center;
}

.bussiness-flex-container-left span {
    font-size: 44px;
    font-weight: 300;
}

.bussiness-flex-container-right {
    margin-left: 21rem;
    font-size: 22px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    width: 36%;
}

.bussiness-flex-container-right-bbb {
    width: 75px;
    margin-right: 25px;
}

.bussiness-flex-container-right-inc {
    width: 65px;
}

/* Business solutions section */
.business-solutions-container {
    padding: 3rem 6rem 0 6rem;
    background-color: rgb(245, 252, 255);
}

.business-solutions {
    display: flex;
    flex-direction: column;
    width: 35%;
    margin-left: 0.5rem;
}

.business-solutions-top {
    font-weight: 300;
    font-size: 38px;
    margin-bottom: 1rem;
}

.business-solutions-bottom {
    font-weight: 300;
    font-size: 16px;
    padding-right: 1rem;
    margin-bottom: 2rem;
}

/* Blue door section */
.blue-container {
    background: linear-gradient(to right, #27aae1, #1287dc, #066dd9);
    /* Gradient background */
    padding: 2rem 7rem;
}

.blue-container-row {
    display: flex;
    align-items: center;
}

.blue-container-image-container {
    flex: 1;
    /* Grow flex items evenly */
    padding: 0;
    /* Adjust padding as needed */
    display: flex;
    justify-content: center;
}

.blue-container-image-container img {
    height: 300px;
}

.blue-container-text-container {
    flex: 1;
    /* Grow flex items evenly */
    padding: 0;
    /* Adjust padding as needed */
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    margin-top: 1rem;
}

.blue-container-text-container span {
    color: white;
    margin: 0;
    font-weight: 700;
    font-size: 33px;
}

.blue-container-text-container p {
    color: white;
    width: 90%;
    font-weight: 300;
    font-size: 28px;
}

.blue-container-text-container u {
    color: white;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

/* Drop down section */
.drop-down-list {
    width: 100%;
}

.drop-down-heading {
    text-align: center;
    width: 52%;
    font-weight: 300;
    font-size: 44px;
    /* Adjust font size as needed */
    margin-bottom: 1rem;
    /* Add spacing between heading and subtitle */
}

.drop-down-subtitle {
    text-align: center;
    font-weight: 300;
    font-size: 23px;
    /* Adjust font size as needed */
    margin-bottom: 3rem;
    /* Add more spacing between subtitle and content */
}

.drop-down-container {
    background-image: url("/public/photos/office-space.jpg");
    background-size: cover;
    background-position: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center horizontally */
    padding: 0 10rem;
}

.nst-gateway-home-page-main-wrapper {
    position: relative;
    margin-top: 93px;
}

.container {
    max-width: 1250px;
    width: 100%;
    padding: 0 25px;
    margin: 0 auto;
}

.nst-home-hero-sec {
	display: flex;
	padding-top: 50px;
	width: 100%;
	gap: 85px;
	padding-bottom: 150px;
	position: relative;
    align-items: center;
}

.nst-hero-right::before {
    content: "";
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(191, 95, 255, 0.2) 0%, rgba(191, 95, 255, 0) 100%);
    width: 600px;
    height: 600px;
    left: -30%;
    bottom: -40%;
    z-index: -1;
}

.nst-home-hero-sec::after {
    position: absolute;
    content: "";
    right: 0;
    top: -45%;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #d5e4f9 0%, rgba(213, 228, 249, 0) 100%);
    transform: translatex(53%);
    z-index: 2;
}

.nst-home-hero-sec > div {
	width: 100%;
	position: relative;
}
.nst-home-hero-sec > div.nst-hero-left {
	max-width: calc(53.5% - 52px);
}
.nst-home-hero-sec > div.nst-hero-right{
    max-width: calc(47.5% - 52px);
}
.main-head-blue {
	color: var(--heading-color);
	line-height: 125.1%;
	margin-bottom: 17px;
}

.main-head-blue span {
    color: #D90000;
}

.nst-hero-left p {
	margin-bottom: 25px;
	line-height: 163%;
}

.get-started-btn {
    background-color: #5f5fff;
    border-radius: 30px;
    padding: 5px;
    color: white;
    text-transform: capitalize;
    height: 60px;
    font-size: 20px;
    font-weight: 800;
    width: 100%;
    position: relative;
    box-shadow: 0px 15px 20px 0px #1e1e6f33;
    font-family: "Mulish", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease;
}

.get-started-btn:hover {
    background-color: #8e8eff;
    color: var(--white);
}

.get-started-btn .txt-btn {
    width: 78%;
    text-align: center;
}

.get-started-btn .MuiButton-endIcon {
    height: 100%;
    background-color: #8e8eff;
    border-radius: 50%;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}

.get-started-btn:hover .MuiButton-endIcon {
    background-color: #5f5fff;
}

.get-started-section {
    display: flex;
    align-items: center;
    gap: 40px;
}

.get-started-btn-section {
    max-width: 252px;
    width: 100%;
}

.phone-number-section a {
    font-size: 24px;
    color: var(--heading-color);
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 10px;
}

.phone-number-section a:hover {
    color: var(--purple);
}

.phone-number-section a svg path {
    fill: var(--purple);
}

.back-view-source {
    position: absolute;
    top: 0;
    z-index: -1;
    left: 0;
    width: 100%;
    height: 100%;
}

.back-view-source svg {
    width: 100%;
    height: 100%;
}

.front-view-main-source {
    position: relative;
    width: 93.4%;
    padding-top: 83.7%;
    margin-top: 25px;
    margin-left: 20px;
    margin-bottom: 25px;
    margin-right: 25px;
}

.front-view-main-source img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    margin: auto;
    border-radius: 130px 0 130px 130px;
    object-fit: cover;
}

.nst-business-main {
    position: relative;
    padding-bottom: 286px;
}

.nst-business-main::after {
    position: absolute;
    background: url(/src/assests/Home/blue_light_bg.png);
    background-position: bottom;
    width: 100%;
    height: 286px;
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
}

.nst-bussiness-wrapper {
    display: flex;
    width: 100%;
    gap: 80px;
    align-items: center;
}

.nst-bussiness-wrapper > div {
    max-width: 50%;
    width: 100%;
    position: relative;
    z-index: 3;
}

.main-business-view-img-upper {
	position: relative;
	width: 100%;
	padding-top: 69.7%;
	border-radius: 30px;
	overflow: hidden;
	border: 5px solid #105EB2;
}

.main-business-view-img-upper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.flex-footer-business-addons-icon {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 20px;
    max-width: 61.2%;
    width: 100%;
}

.flex-footer-business-addons-icon > span {
    background-color: white;
    padding: 17px 20px;
    border-radius: 15px;
    box-shadow: 0px 15px 20px 0px #1e1e6f33;
    position: relative;
    width: 100%;
    padding-top: 24%;
}

.flex-footer-business-addons-icon > span img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 75%;
    height: 76%;
}

.business-right h2 {
	text-align: center;
	color: #105eb2;
}

.business-round-wrap-border-view-card {
    margin-top: 28px;
}

.business-round-wrap-content-inside {
    padding: 54px 54px;
    border: 10px solid #eaf1fc;
    border-radius: 15px;
    background-color: #fff;
}

.business-reg-dep-oper-sec {
    background-color: #eaf1fc;
    padding-top: 80px;
    padding-bottom: 194px;
}
.business-reg-dep-oper-sec-top {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0 auto 45px;
	border-bottom: 1px solid #d9d9d9;
	padding-bottom: 33px;
	flex-direction: column;
	text-align: center;
	gap: 20px;
}



.business-reg-dep-oper-sec-top p {
	max-width: 752px;
	color: #105EB2;
	width: 100%;
}

.business-sreg-dep-oper-sec-bottom .business-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.business-sreg-dep-oper-sec-bottom .business-cards > div {
    max-width: calc(50% - 20px);
    display: flex;
    position: relative;
    align-items: stretch;
}

.business-sreg-dep-oper-sec-bottom .business-frame-icon {
    max-width: 120px;
    background-color: #096dda;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    border-radius: 15px;
    box-shadow: 0px 15px 20px 0px #1e1e6f33;
    width: 100%;
    position: relative;
    margin-top: 50px;
}

.business-sreg-dep-oper-sec-bottom .business-frame-icon::after {
    content: "";
    position: absolute;
    right: -19px;
    top: 50%;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #096dda;
    transform: translateY(-50%);
}

.business-sreg-dep-oper-sec-bottom .business-card-content {
    background-color: white;
    border-radius: 30px 0px 30px 30px;
    padding: 40px 40px 40px 135px;
    margin-left: -70px;
    border: 1px solid #d9d9d9;
}

.business-reg-dep-oper-sec .get-started-btn-section {
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 100%;
}

.business-reg-dep-oper-sec .get-started-btn-section::after {
    content: "";
    border-bottom: 1px solid #d9d9d9;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.business-reg-dep-oper-sec .get-started-btn-section .get-started-btn {
    z-index: 1;
    max-width: 21.5%;
    width: 100%;
}

.business-sreg-dep-oper-sec-bottom {
    margin-bottom: 45px;
}

.trusted-partner-main {
    background-image: url(/src/assests/Home/line-bg.png), linear-gradient(93.9deg, #10d4e8 0.61%, #096dda 63.46%);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}

.trusted-partner-wrap {
    display: flex;
    justify-content: space-between;
    padding: 54px 0 65px;
    gap: 90px;
    align-items: flex-start;
}

.business-trusted-partner-meet-img {
    margin-top: -120px;
    border-radius: 30px;
    overflow: hidden;
    max-width: calc(47.5% - 35px);
    width: 100%;
    position: relative;
    padding-top: 32%;
    box-shadow: 0px 40px 45px 0px #1e1e6f4d;
}

.business-trusted-partner-meet-img img {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    object-fit: cover;
}

.trusted-partner-content-main-section {
    max-width: calc(48% - 35px);
    width: 100%;
}

.trusted-partner-content-main-section h2 {
    color: var(--white);
    margin-top: 0;
    margin-bottom: 25px;
}

.trusted-partner-content-main-section p {
    color: var(--white);
    margin-bottom: 28px;
    margin-top: 0;
}

.navigate-use-btn button.MuiButtonBase-root {
    background-color: white;
    border-radius: 30px;
    padding: 15px 45px;
    font-size: 20px;
    transition: all 0.5s ease;
    color: var(--purple);
    text-transform: capitalize;
    line-height: 150%;
    font-family: "Mulish", sans-serif;
    width: fit-content;
    font-weight: 800;
}

.nst-gateway-contact-us-phone-calling .nst-gateway-contact-us-call-us-on a {
    color: #ffffff;
}
.navigate-use-btn button.MuiButtonBase-root:hover{
    color: var(--white);
    background-color: var(--purple);
}

.payment-main-container {
    padding-top: 60px;
    position: relative;
    padding-bottom: 90px;
}

.payment-main-container::before {
    content: "";
    background: radial-gradient(50% 50% at 50% 50%, rgba(191, 95, 255, 0.2) 0%, rgba(191, 95, 255, 0) 100%);
    width: 709px;
    height: 709px;
    display: block;
    position: absolute;
    left: -310px;
    top: -70px;
}

.payment-main-wrap::after {
    content: "";
    background: radial-gradient(50% 50% at 50% 50%, #d5e4f9 0%, rgba(213, 228, 249, 0) 100%);
    width: 864px;
    height: 677px;
    position: absolute;
    bottom: 0;
    right: -180px;
    z-index: -1;
}

.payment-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
}

.payment-lable-pannel p {
    background-color: #ddeaff;
    display: inline-block;
    padding: 9px 18px;
    border-radius: 8px;
    font-weight: 400;
    color: #000;
    margin-bottom: 18px;
}

.payment-content {
    max-width: calc(50% - 33px);
    width: 100%;
}

.payment-grow-business-chart-img-right {
    position: relative;
    width: 100%;
    max-width: calc(49.7% - 62px);
    border-radius: 30px;
    overflow: hidden;
    padding-top: 21.4%;
}

.payment-grow-business-chart-img-right img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
}

.payment-bottom {
    margin-top: 132px;
}

.payment-business-card {
    display: flex;
    justify-content: center;
    gap: 25px;
    width: 100%;
    flex-wrap: wrap;
}
.business-card-para p {
	min-height: 186px;
}
.payment-business-card > div {
	max-width: calc(33.33% - 17px);
	background-color: #f8f8f8;
	border-radius: 15px;
	position: relative;
	padding-top: 79px;
	padding-left: 35px;
	padding-right: 35px;
	padding-bottom: 18px;
	margin-bottom: 70px;
	z-index: 1;
}

.payment-business-card > div .business-frame-icon {
    position: absolute;
    top: -70px;
    left: 0;
    right: 0;
    margin: auto;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translateY(10px);
    box-shadow: 0px 10px 15px 0px #14515726;
}

.pay-sec-proprietary-gateway .business-frame-icon {
    background-color: #0adef3;
}

.pay-sec-responsive-support .business-frame-icon {
    background-color: #096dda;
}

.pay-sec-reliable-security .business-frame-icon {
    background-color: #5f5fff;
}

.pay-sec-flexible-accessibility .business-frame-icon {
    background-color: #096dda;
}

.pay-sec-developer-frd-api .business-frame-icon {
    background-color: #0adef3;
}

.payment-business-card .business-card-main-heading-title h4 {
    text-align: center;
    margin-bottom: 20px;
}

.business-card-main-heading-title h4 {
    margin-bottom: 20px;
}

.payment-navigation-btn {
    display: flex;
    justify-content: center;
}

.payment-main-container::after {
    content: "";
    position: absolute;
    bottom: -140px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(191, 95, 255, 0.2) 0%, rgba(191, 95, 255, 0) 100%);
    width: 490px;
    height: 387px;
    right: -180px;
    z-index: -1;
}

.form-fill-section-main {
    background-image: url(/src/assests/Home/form_bg.png);
    background-color: rgba(5, 59, 117, 0.74);
    background-size: cover;
    background-blend-mode: multiply;
}

.form-fill-out-main-form {
    margin: 0 auto;
}

.form-fill-main-container {
    display: flex;
    gap: 54px;
    align-items: center;
    padding-top: 95px;
    padding-bottom: 66px;
}

.form-fill-main-container > div {
    max-width: calc(50% - 27px);
}

.form-fill-heading h2 {
    color: var(--white);
    margin-top: 0;
    margin-bottom: 38px;
}

.form-fill-content-below p {
    color: var(--white);
    margin-bottom: 0;
}

.form-fill-main-wrapper {
    position: relative;
    background-color: #ffffff30;
    box-shadow: 0px 40px 45px 0px #1e1e6f33;
    border-radius: 30px;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    text-align: center;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-fill-main-wrapper .nst-gateway-logo-main {
    position: absolute;
    top: -7%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    display: flex;
    width: fit-content;
    border-radius: 15px;
    transform: translateY(7%);
    justify-content: center;
    padding: 20px;
}

.form-fill-main-heading-content h5 {
    color: var(--white);
    text-transform: uppercase;
    line-height: 140%;
    margin-top: 20px;
}

.form-fill-main-input-fields .MuiBox-root {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
}

.form-fill-main-input-fields .MuiBox-root .MuiInputBase-root {
    border: 1px solid var(--white);
    border-radius: 5px;
    font-family: "Mulish", sans-serif;
    opacity: 1;
    color: var(--white);
}

.form-fill-main-input-fields .MuiBox-root .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border-width: 0;
}

.form-fill-main-input-fields .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.form-fill-main-input-fields .MuiBox-root .MuiInputBase-root input {
    color: var(--white);
    opacity: 1;
    height: 1.138em;
}

.form-fill-main-input-fields .MuiBox-root .MuiInputBase-root input::placeholder,
.form-fill-main-input-fields .MuiBox-root .MuiInputBase-root textarea::placeholder {
    color: var(--white);
    opacity: 1;
}

.form-fill-main-input-fields .MuiBox-root .MuiInputAdornment-root {
    color: #0adef3;
}

.form-fill-main-input-fields {
    margin-bottom: 21px;
}

.sec-payments-card-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

.store-card-main-container:nth-child(2n) .sec-payments-card-main {
    flex-direction: row-reverse;
}

.sec-payments-card-main .sec-payments-lable-main {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.sec-payments-card-main.card-sec-payment-on-web-store {
    padding-bottom: 180px;
}

.sec-payments-card-alpha {
    max-width: calc(50% - 70px);
    width: 100%;
}

.sec-payments-card-image-preview-beta {
    max-width: calc(50% - 30px);
    width: 100%;
    position: relative;
    height: 100%;
}

.sec-payment-small-img {
    position: absolute;
    width: 58.5%;
    height: 57.5%;
    bottom: -27%;
    left: 0;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 20px 20px 0px #1e1e6f33;
    transform: translateY(27%);
}

.sec-payment-small-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.sec-payment-big-img {
    position: relative;
    width: 100%;
    padding-top: 56.9%;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 20px 20px 0px #1e1e6f33;
}

.img-card-girl-shopping .sec-payment-big-img,
.img-card-business-integrated-chart .sec-payment-big-img {
    padding-top: 69.3%;
}

.sec-payments-card-img-main.img-card-shopify-woobusiness,
.sec-payments-card-img-main.img-card-business-integrated-chart {
    margin-left: 60px;
}

.sec-payments-card-img-main.img-card-girl-shopping {
    margin-right: 70px;
}

.sec-payment-big-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sec-payments-lable-main h2 {
    color: #096dda;
    border: 1px solid #d9d9d9;
    background-color: #f8f8f8;
    border-radius: 15px;
    padding: 14px;
    max-width: 53.2%;
    width: 100%;
    text-align: center;
}

.store-card-main-container {
    position: relative;
    z-index: 1;
}

.store-card-main-container::before {
    position: absolute;
    content: "";
    width: 1px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    height: 100%;
    border-left: 1px solid #d9d9d9;
}

.business-stores-content {
    margin-top: 70px;
    position: relative;
}

.business-stores-content::before {
    position: absolute;
    content: "";
    background: radial-gradient(50% 50% at 50% 50%, #d5e4f9 0%, rgba(213, 228, 249, 0) 100%);
    width: 870px;
    height: 1220px;
    top: -10%;
    left: -20%;
    z-index: -1;
}

.business-stores-content::after {
    content: "";
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, #d5e4f9 0%, rgba(213, 228, 249, 0) 100%);
    width: 820px;
    height: 1220px;
    bottom: 0;
    right: -20%;
    z-index: -1;
}

.all-store-cards::after {
    content: "";
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(191, 95, 255, 0.2) 0%, rgba(191, 95, 255, 0) 100%);
    bottom: 0;
    width: 490px;
    height: 690px;
    right: -8%;
}

.sec-payments-card-cntn-heading h3 {
    margin-bottom: 27px;
}

.sec-payments-card-cntn-explore-text p {
    margin-bottom: 37px;
}

.sec-payments-card-btn {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.store-card-btn .get-started-btn {
    max-width: 80%;
    width: 100%;
}

.store-card-btn .get-started-btn.Mui-disabled {
    color: var(--white);
    background-color: #aeaee3;
}
.store-card-btn .get-started-btn .txt-btn {
    width: 89%;
}

.store-card-btn .get-started-btn.Mui-disabled .MuiButton-endIcon {
    color: var(--white);
    background-color: #ffffff4a;
}

.card-sec-payments-in-stores .store-card-btn .get-started-btn,
.card-sec-integrated-business-system .store-card-btn .get-started-btn {
    max-width: 59%;
    width: 100%;
}
p.MuiFormHelperText-root.Mui-error {
    color: #ef5350;
    font-size: 0.9em;
}
.form-fill-main-input-fields .MuiInputBase-root.Mui-error {
    border-color: #ef5350;
}
@media screen and (max-width: 1200px) {
    .nst-home-hero-sec {
        padding-bottom: 90px;
    }

    .get-started-btn {
        font-size: 18px;
    }

    .phone-number-section a {
        font-size: 22px;
    }

    .business-round-wrap-content-inside {
        padding: 44px;
    }

    .nst-business-main {
        padding-bottom: 196px;
    }

    .business-reg-dep-oper-sec {
        padding-top: 40px;
        padding-bottom: 124px;
    }

    .business-sreg-dep-oper-sec-bottom .business-card-content {
        padding: 30px 30px 30px 125px;
        margin-left: -70px;
    }

    .trusted-partner-wrap {
        gap: 30px;
    }

    .business-trusted-partner-meet-img {
        max-width: calc(47.5% - 15px);
    }

    .trusted-partner-content-main-section {
        max-width: calc(50% - 15px);
    }

    .navigate-use-btn .get-started-btn {
        font-size: 18px;
    }

    .business-card-para p {
        min-height: 176px;
    }
    .payment-business-card > div {
        padding-left: 25px;
        padding-right: 25px;
    }
    .payment-business-card > div .business-frame-icon {
        top: -60px;
        transform: translateY(10px);
        width: 100px;
        height: 100px;
    }

    .payment-main-container {
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 1024px) {
    .get-started-btn-section {
        max-width: 232px;
    }

    .get-started-btn {
        font-size: 16px;
        height: 47px;
    }

    .get-started-btn .MuiButton-endIcon {
        width: 40px;
    }

    .phone-number-section a {
        font-size: 20px;
    }

    .business-round-wrap-content-inside {
        padding: 34px;
    }

    .nst-business-main {
        padding-bottom: 166px;
    }

    .business-reg-dep-oper-sec {
        padding-top: 20px;
    }

    .navigate-use-btn .get-started-btn {
        font-size: 16px;
    }

    .form-fill-main-wrapper .nst-gateway-logo-main {
        padding: 15px;
    }

    .form-fill-main-wrapper {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 30px;
    }

    .form-fill-heading h2 {
        margin-bottom: 20px;
    }

    .sec-payments-card-cntn-heading h3 {
        margin-bottom: 17px;
    }

    .store-card-btn .get-started-btn {
        font-size: 16px;
    }

    .store-card-btn .get-started-btn .MuiButton-endIcon svg {
        height: 28px;
    }

    .front-view-main-source img {
        border-radius: 80px 0 80px 80px;
    }
    .navigate-use-btn button.MuiButtonBase-root,
    .navigate-use-btn .get-started-btn {
        padding: 12px 45px;
        font-size: 16px;
    }
}

@media screen and (max-width: 898px) {
    .nst-home-hero-sec {
        padding-bottom: 40px;
    }

    .get-started-btn-section {
        max-width: 200px;
    }

    .phone-number-section a {
        font-size: 18px;
    }

    .business-round-wrap-border-view-card {
        margin-top: 18px;
    }

    .business-round-wrap-content-inside {
        padding: 24px;
    }

    .nst-business-main {
        padding-bottom: 146px;
    }

    .business-sreg-dep-oper-sec-bottom .business-cards {
        gap: 20px;
    }

    .business-sreg-dep-oper-sec-bottom .business-cards > div {
        max-width: calc(50% - 10px);
    }

    .business-sreg-dep-oper-sec-bottom .business-card-content {
        padding: 30px 20px 30px 65px;
        margin-left: -40px;
    }

    .business-sreg-dep-oper-sec-bottom .business-frame-icon {
        max-width: 90px;
        height: 90px;
        min-width: 90px;
    }

    .trusted-partner-content-main-section h2 {
        margin-bottom: 15px;
    }

    .trusted-partner-content-main-section p {
        margin-bottom: 15px;
    }

    .business-trusted-partner-meet-img {
        margin-top: -90px;
    }

    .business-reg-dep-oper-sec {
        padding-bottom: 84px;
    }

    .trusted-partner-wrap {
        padding: 44px 0 45px;
    }

    .payment-business-card > div .business-frame-icon {
        height: 90px;
        top: -55px;
        transform: translateY(10px);
        width: 90px;
    }
    .business-card-para p {
        min-height: 116px;
    }

    .payment-business-card .business-card-main-heading-title h4 {
        margin-bottom: 10px;
    }

    .payment-business-card > div {
        padding-top: 60px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        margin-bottom: 40px;
        max-width: calc(33.33% - 11px);
    }

    .payment-bottom {
        margin-top: 90px;
    }

    .payment-business-card {
        gap: 16px;
    }

    .form-fill-main-wrapper {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    .form-fill-main-input-fields .MuiBox-root .MuiInputBase-root input {
        height: 0.838em;
    }

    .sec-payments-card-main.card-sec-payment-on-web-store {
        padding-bottom: 50px;
    }

    .card-sec-payments-in-stores .store-card-btn .get-started-btn,
    .card-sec-integrated-business-system .store-card-btn .get-started-btn {
        max-width: 85%;
    }
    .store-card-btn .get-started-btn {
        max-width: 100%;
    }

    .sec-payments-card-img-main.img-card-business-integrated-chart {
        margin-left: 10px;
    }
    .nst-bussiness-wrapper {
        gap: 40px;
    }
}

@media screen and (max-width: 768px) {
    /* Header Section */
    .circles {
        position: absolute;
        bottom: -28%;
        /* Move the circles further down */
        left: -5%;
        /* Move the circles further to the left */
        width: 30%;
        height: 0;
        padding-bottom: 30%;
        border: 1px solid rgb(232, 232, 232);
        border-radius: 50%;
    }

    .circles::before {
        content: "";
        position: absolute;
        bottom: 5%;
        left: 6%;
        width: 85%;
        height: 0;
        padding-bottom: 85%;
        border: 1px solid rgb(232, 232, 232);
        border-radius: 50%;
    }

    .flex-container {
        margin: 1rem 5%;
        height: 40vh;
    }

    .left-column {
        display: flex;
        flex-direction: column;
        /* Set flex-direction to column */
        justify-content: center;
    }

    .left-column h1 {
        font-size: 18px;
        width: 100%;
    }

    .left-column-subtitle {
        font-size: 14px;
        width: 80%;
    }

    .left-column-button {
        font-size: 0.7rem;
        width: 15vw;
    }

    .left-column-phone-number {
        font-size: 0.8rem;
    }

    .right-column {
        display: flex;
        width: auto;
    }

    .right-column img {
        width: 35vw;
        height: 40vh;
    }

    /* Business Overview section */
    .bussiness-container {
        background-color: rgb(255, 255, 255);
        margin: 3rem 5rem;
    }

    .bussiness-flex-container {
        display: flex;
        justify-content: space-between;
    }

    .bussiness-flex-container-right {
        margin-left: 3rem;
        font-size: 22px;
        font-weight: 200;
        display: flex;
        flex-direction: column;
    }

    /* Business solutions section */
    .business-solutions-container {
        padding: 2rem 3rem;
        background-color: rgb(245, 252, 255);
    }

    .business-solutions {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
    }

    .business-solutions-top {
        font-weight: 300;
        font-size: 38px;
        margin-bottom: 1rem;
    }

    .business-solutions-bottom {
        font-weight: 200;
        font-size: 13px;
        padding-right: 1rem;
        margin-bottom: 2rem;
    }

    /* Blue door section */
    .blue-container {
        background: linear-gradient(to right, #27aae1, #1287dc, #066dd9);
        /* Gradient background */
        padding: 1rem 2rem;
    }

    .blue-container-row {
        display: flex;
        align-items: center;
    }

    .blue-container-image-container {
        flex: 1;
        /* Grow flex items evenly */
        padding: 0;
        /* Adjust padding as needed */
        display: flex;
        justify-content: center;
    }

    .blue-container-image-container img {
        height: 300px;
    }

    .blue-container-text-container {
        flex: 2;
        /* Grow flex items evenly */
        padding: 0;
        /* Adjust padding as needed */
        display: flex;
        flex-direction: column;
    }

    .blue-container-text-container span {
        color: white;
        margin: 0;
        font-weight: 700;
        font-size: 28px;
    }

    .blue-container-text-container p {
        color: white;
        width: 100%;
        font-weight: 100;
        font-size: 24px;
    }

    .blue-container-text-container u {
        color: white;
        font-size: 13px;
    }

    /* Drop down section */
    .drop-down-list {
        width: 100%;
    }

    .drop-down-heading {
        text-align: center;
        width: 70%;
        font-weight: 300;
        font-size: 44px;
        /* Adjust font size as needed */
        margin-bottom: 1rem;
        /* Add spacing between heading and subtitle */
    }

    .drop-down-subtitle {
        text-align: center;
        font-weight: 200;
        font-size: 23px;
        /* Adjust font size as needed */
        margin-bottom: 3rem;
        /* Add more spacing between subtitle and content */
    }

    .drop-down-container {
        background-image: url("/public/photos/office-space.jpg");
        background-size: cover;
        background-position: center;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* Center horizontally */
        padding: 2rem;
    }

    .nst-home-hero-sec {
        padding-top: 10px;
        gap: 45px;
    }

    .nst-home-hero-sec > div.nst-hero-left {
        max-width: calc(53.5% - 22px);
    }
    .nst-home-hero-sec > div.nst-hero-right {
        max-width: calc(47.5% - 22px);
    }
    .get-started-btn-section {
        max-width: 150px;
    }

    .get-started-section {
        gap: 15px;
    }

    .get-started-btn {
        font-size: 14px;
    }

    .phone-number-section a {
        font-size: 16px;
    }

    .flex-footer-business-addons-icon {
        gap: 10px;
    }

    .flex-footer-business-addons-icon > span {
        border-radius: 12px;
    }

    .nst-business-main {
        padding-bottom: 100px;
    }

    .nst-bussiness-wrapper {
        gap: 40px;
    }

    

    .business-reg-dep-oper-sec-top {
        margin: 0 auto 35px;
        padding-bottom: 23px;
    }

    .payment-main-container {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .payment-bottom {
        margin-top: 70px;
    }

    .payment-business-card {
        gap: 26px;
    }

    .payment-business-card > div {
        padding-top: 80px;
        margin-bottom: 30px;
        max-width: calc(50% - 26px);
    }

    .form-fill-main-container {
        gap: 24px;
        padding-top: 65px;
        padding-bottom: 46px;
    }

    .form-fill-main-container > div {
        max-width: calc(50% - 13px);
    }

    .form-fill-main-input-fields .MuiBox-root .MuiInputBase-root {
        font-size: 0.8rem;
    }

    .business-stores-content {
        margin-top: 40px;
    }

    .sec-payments-card-main .sec-payments-lable-main {
        margin-bottom: 30px;
    }

    .sec-payments-card-alpha {
        max-width: calc(50% - 30px);
    }

    .sec-payments-card-img-main.img-card-shopify-woobusiness {
        margin-left: 40px;
    }

    .sec-payments-card-img-main.img-card-girl-shopping {
        margin-right: 0;
    }

    .store-card-btn .get-started-btn {
        height: 48px;
        font-size: 14px;
        max-width: 97%;
    }

    .card-cntn-btn-payment-on-web-store .store-card-btn .get-started-btn .MuiButton-icon svg {
        width: 25px;
        height: 25px;
    }

    .sec-payments-card-img-main.img-card-business-integrated-chart {
        margin-left: 10px;
    }

    .sec-payments-card-main {
        padding-bottom: 20px;
    }

    .front-view-main-source img {
        border-radius: 60px 0 60px 60px;
    }

    .nst-gateway-content-upper-section {
        padding-bottom: 20px;
        margin-bottom: 26px;
    }

    .nst-gateway-on-social-media > div {
        width: 41px;
        height: 41px;
    }

    .nst-footer-top {
        margin-bottom: 17px;
    }

    .business-sreg-dep-oper-sec-bottom .business-frame-icon svg {
        width: auto;
        height: auto;
    }

    .business-sreg-dep-oper-sec-bottom .business-frame-icon::after {
        right: -14px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid #096dda;
    }

    .business-sreg-dep-oper-sec-bottom .business-card-content {
        padding: 25px 20px 30px 46px;
        margin-left: -28px;
    }

    .business-card-main-heading-title h4 {
        margin-bottom: 15px;
    }

    .sec-payments-card-cntn-heading h3 {
        margin-bottom: 12px;
    }

    .payment-main-container::before {
        top: 0;
    }
    .navigate-use-btn button.MuiButtonBase-root,
    .navigate-use-btn .get-started-btn {
        padding: 11px 25px;
        font-size: 14px;
    }
    .form-fill-main-input-fields {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 650px) {
    .left-column h1 {
        font-size: 18px;
        width: 100%;
    }

    .left-column-subtitle {
        font-size: 14px;
    }

    .nst-home-hero-sec {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .nst-home-hero-sec > div {
        max-width: 100%;
    }

    .nst-bussiness-wrapper {
        flex-direction: column;
        gap: 60px;
    }

    .nst-bussiness-wrapper > div {
        max-width: 100%;
    }

    .nst-business-main {
        padding-bottom: 80px;
    }

    .business-reg-dep-oper-sec-top {
        padding-bottom: 23px;
        flex-direction: column;
        gap: 15px;
        margin: 0 auto 25px;
    }

    .business-reg-dep-oper-sec-top h2 {
        max-width: 100%;
    }

    .business-reg-dep-oper-sec-top p {
        max-width: 100%;
    }

    .business-sreg-dep-oper-sec-bottom .business-cards {
        flex-direction: column;
    }

    .business-sreg-dep-oper-sec-bottom .business-cards > div {
        max-width: 100%;
    }

    .business-sreg-dep-oper-sec-bottom .business-card-content {
        padding: 25px 25px 30px 56px;
    }

    .business-reg-dep-oper-sec .get-started-btn-section .get-started-btn {
        max-width: 41.5%;
    }

    .trusted-partner-wrap {
        flex-direction: column;
        padding: 44px 0 25px;
    }

    .business-trusted-partner-meet-img {
        max-width: 100%;
        padding-top: 64%;
    }

    .trusted-partner-content-main-section {
        max-width: 100%;
    }

    .payment-top {
        flex-direction: column;
        gap: 30px;
    }

    .payment-content {
        max-width: 100%;
    }

    .payment-grow-business-chart-img-right {
        max-width: 100%;
        padding-top: 51.4%;
    }

    .payment-business-card > div {
        max-width: calc(50% - 13px);
    }

    .form-fill-main-container {
        gap: 54px;
        padding-top: 52px;
        padding-bottom: 36px;
        flex-direction: column;
    }

    .form-fill-main-container > div {
        max-width: 100%;
    }

    .sec-payments-lable-main h2 {
        max-width: 73.2%;
        width: 100%;
        text-align: center;
    }

    .sec-payments-card-alpha {
        max-width: 100%;
    }

    .sec-payments-card-image-preview-beta {
        max-width: 100%;
    }

    .store-card-main-container::before {
        content: unset;
    }

    .sec-payments-card-main .sec-payments-lable-main {
        margin-bottom: 0;
    }

    .store-card-btn .get-started-btn {
        max-width: 57%;
    }

    .sec-payments-card-main.card-sec-payment-on-web-store {
        padding-bottom: 170px;
    }

    .sec-payments-card-main {
        gap: 20px;
    }

    .card-sec-payments-in-stores .store-card-btn .get-started-btn,
    .card-sec-integrated-business-system .store-card-btn .get-started-btn {
        max-width: 38%;
    }

    .sec-payments-card-cntn-explore-text p {
        margin-bottom: 17px;
    }

    .sec-payments-card-img-main.img-card-business-integrated-chart {
        margin-left: 0;
    }

   

    .nst-gateway-logo-main {
        display: inline-block;
        padding: 10px;
        cursor: pointer;
    }

    .form-fill-main-wrapper {
        border-radius: 20px;
    }
    .business-sreg-dep-oper-sec-bottom .business-frame-icon {
        margin-top: 30px;
    }
    .nst-home-hero-sec > div.nst-hero-right {
        max-width: 100%;
    }
    .nst-home-hero-sec > div.nst-hero-left {
        max-width: 100%;
    }
    .main-head-blue br {
        display: none;
    }
}

@media screen and (max-width: 475px) {
    .payment-business-card > div {
        max-width: 100%;
        padding-bottom: 35px;
        padding-top: 70px;
    }
    .payment-business-card > div .business-frame-icon {
        top: -24%;
        transform: translateY(23%);
    }
    .sec-payments-lable-main h2 {
        max-width: 100%;
    }
    .store-card-btn .get-started-btn {
        max-width: 80%;
    }
    .sec-payments-card-main.card-sec-payment-on-web-store {
        padding-bottom: 120px;
    }
    .card-sec-payments-in-stores .store-card-btn .get-started-btn,
    .card-sec-integrated-business-system .store-card-btn .get-started-btn {
        max-width: 70%;
    }

    .business-reg-dep-oper-sec .get-started-btn-section .get-started-btn {
        max-width: 51.5%;
    }
    .form-fill-main-wrapper .nst-gateway-logo-main {
        padding: 10px;
    }
    .nst-gateway-on-social-media {
        justify-content: flex-start;
        gap: 10px;
    }
    .business-card-para p {
        min-height: unset;
    }
}
@media screen and (max-width: 480px) {
    /* Header Section */
    .circles {
        display: none;
        /* Hide circles on smaller screens */
    }

    .flex-container-origin {
        margin: 0;
        padding: 0;
    }

    .flex-container {
        margin: 1rem 1rem 0 1rem;
        padding: 0;
    }

    .left-column h1 {
        font-size: 14px;
        width: 100%;
    }

    .left-column-subtitle {
        font-size: 11px;
        width: 100%;
    }

    .left-column-button {
        font-size: 1rem;
        width: 50vw;
    }

    .left-column-phone-number {
        font-size: 11px;
    }

    .right-column {
        display: flex;
        flex-direction: column;
        /* Set flex-direction to column */
        justify-content: left;
        padding-top: 2vh;
    }

    .right-column img {
        top: 50%;
        width: auto;
        height: 25vh;
    }

    /* Business Overview section */
    .bussiness-container {
        background-color: rgb(255, 255, 255);
        margin: 1rem;
    }

    .bussiness-flex-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bussiness-flex-container-left {
        width: 100%;
        vertical-align: center;
        text-align: center;
    }

    .bussiness-flex-container-left span {
        font-size: 44px;
        font-weight: lighter;
    }

    .bussiness-flex-container-right {
        font-size: 18px;
        font-weight: 200;
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        margin: 0;
    }

    .bussiness-flex-container-right-bbb {
        width: 75px;
        margin: 0;
    }

    .bussiness-flex-container-right-inc {
        width: 65px;
    }

    /* Business solutions section */
    .business-solutions-container {
        padding: 1rem 2rem;
        background-color: rgb(245, 252, 255);
    }

    .business-solutions {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
    }

    .business-solutions-top {
        font-weight: 300;
        font-size: 33px;
        margin-bottom: 1rem;
    }

    .business-solutions-bottom {
        font-weight: 200;
        font-size: 14px;
        padding-right: 1rem;
        margin-bottom: 2rem;
    }

    /* Blue door section */
    .blue-container {
        background: linear-gradient(to right, #27aae1, #1287dc, #066dd9);
        /* Gradient background */
        padding: 0.5rem;
    }

    .blue-container-row {
        display: flex;
        align-items: center;
    }

    .blue-container-image-container {
        display: none;
    }

    .blue-container-text-container {
        flex: 1;
        /* Grow flex items evenly */
        padding: 0;
        /* Adjust padding as needed */
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
        width: 100%;
    }

    .blue-container-text-container span {
        color: white;
        margin: 0;
        font-weight: 700;
        font-size: 33px;
        text-align: center;
    }

    .blue-container-text-container p {
        color: white;
        width: 100%;
        font-weight: 100;
        font-size: 28px;
        text-align: center;
    }

    .blue-container-text-container u {
        color: white;
        font-size: 13px;
        text-align: right;
        display: flex;
        margin-left: 30%;
    }

    /* Drop down section */
    .drop-down-list {
        width: 100%;
    }

    .drop-down-heading {
        text-align: center;
        width: 100%;
        font-weight: 300;
        font-size: 33px;
        /* Adjust font size as needed */
        margin-bottom: 1rem;
        /* Add spacing between heading and subtitle */
    }

    .drop-down-subtitle {
        text-align: center;
        font-weight: 200;
        font-size: 23px;
        /* Adjust font size as needed */
        margin-bottom: 3rem;
        /* Add more spacing between subtitle and content */
    }

    .drop-down-container {
        background-image: url("/public/photos/office-space.jpg");
        background-size: cover;
        background-position: center;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* Center horizontally */
        padding: 0.3rem;
    }
}
@media screen and (max-width: 375px) {
    .get-started-section {
        align-items: flex-start;
        flex-direction: column;
    }
    .sec-payments-card-main.card-sec-payment-on-web-store {
        padding-bottom: 100px;
    }
    .business-sreg-dep-oper-sec-bottom {
        margin-bottom: 25px;
        margin-top: 60px;
    }
    .business-sreg-dep-oper-sec-bottom .business-cards {
        gap: 60px;
    }
    .business-sreg-dep-oper-sec-bottom .business-frame-icon {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .business-sreg-dep-oper-sec-bottom .business-card-content {
        padding: 65px 25px 30px 25px;
        margin-left: 0;
    }
    .nst-gateway-content-upper-section {
        gap: 20px;
        flex-direction: column;
    }
    .nst-gateway-ready-to-accept-payment-heading-content {
        max-width: 100%;
    }
    .nst-gateway-content-below-support-link-section .columns-container {
        flex-direction: column;
    }
    .flex-footer-business-addons-icon {
        bottom: -30px;
    }
    .store-card-btn .get-started-btn {
        max-width: 100%;
    }
}

/* to :disabled unavailable content link in the app */
.disabled-link {
    pointer-events: none;
    cursor: default;
    color: inherit;
    text-decoration: none;
}

/* FAQ Link Style  */
.faq-link {
    color: #096DDA;
    text-decoration: underline;
}